import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

export const Breadcrumbs = ({ path, className, as }) => {
  const pathNames = typeof path === "string" ? path.split("/") : null

  return (
    <ol className={`breadcrumb ${className}`}>
      {typeof path === "string"
        ? pathNames.map((item, index) => {
            if (index === 0) {
              return (
                <li className="breadcrumb-item" key={`path-home`}>
                  <Link to="/" className="fx-underline">
                    Home
                  </Link>
                </li>
              )
            } else if (index === pathNames.length - 1 && item !== "") {
              let name = item.split("-")
              return (
                <li className="breadcrumb-item" key={`path-${item}`}>
                  {item === "bsd-city" ? "BSD City" : name.join(" ")}
                </li>
              )
            } else if (item === "") {
              return null
            } else {
              let slicedPath = pathNames.slice(0, index + 1)
              let url = slicedPath.join("/")
              let name = item.split("-")

              return (
                <li className="breadcrumb-item" key={`path-${item}`}>
                  {as === "text" ? (
                    item === "bsd-city" ? (
                      "BSD City"
                    ) : (
                      name.join(" ")
                    )
                  ) : (
                    <Link to={url} className="fx-underline">
                      {item === "bsd-city" ? "BSD City" : name.join(" ")}
                    </Link>
                  )}
                </li>
              )
            }
          })
        : null}
    </ol>
  )
}

Breadcrumbs.propTypes = {
  path: PropTypes.string,
}
